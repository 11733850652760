import helper from "~/resources/js/libraries/helper";

export const useClientFetch = async (
  url,
  method = 'GET',
  options
) => {
  const language_code = useNuxtApp().$i18n.locale.value || 'en';
  const currency = helper.cookies.currency();

  return await $fetch(`/api/${url}`, {
    method: method,
    headers: {
      "Application-Currency": currency.value.code,
      "Application-Language": language_code,
    },
    ...options
  })
}
